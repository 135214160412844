.account {
    width: 750px;
    display: flex;
    border: 1px;
    justify-content: space-evenly;
   align-self: flex-start;
    flex-direction: row;
    text-overflow: ellipsis;
    height: 50px;  
}
.insContainer {
    width: 1000px;
    display: flex;
    border: 1px;
    justify-content: space-evenly;
    flex-direction: row;
    height: 50px; 
}
.accountContainer {
   display: flex;
   flex-direction: column;
}

.disconnect {
    color: blue;
    width: 250px;
    font-size: 22px;
}
.header {
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 1000px;
    margin-top: 15%;

}
button {
    position: relative;
}
u {
    color: black;
    width: 250px;
}

h2 {
    color: black;
    width: 250px;
    font-size: 22px;
    text-overflow: clip;
    font-weight: 300;
   
}
h3 {
    position: relative;
    font-size: 40px;
    top: 100px;
    text-align: center;
    color: black;
}
.linker {
    background: none;
    font-size: 35;
    border: 0;
    color: blue;
    overflow: hidden;
    position: fixed;
    bottom: 20%;
    left: 43.5%;
}
.navBar {
    position: fixed;
    z-index: 0;
}
.container {
    width: 'max-content';
    overflow: auto;
    justify-content: center;
    margin-left: 50px;
    margin-top: -75px;
    padding-bottom: 100px;
}
.modalBack {
    background-color: black;
    
    width: 10000;
    display: flex;
    height: 10000;
    z-index: 10;
}
::-webkit-scrollbar {
  display: none;
}
