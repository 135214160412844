
.auth-style {
    height: 100%;
    background-position: clamp(26%, 26%, 28%) 100%;
    background-repeat: no-repeat;
    background-size: auto clamp(0px, 55%, 55%);
}

.credit {
    display: none;
}

.auth-left-wrapper > div {
    margin: 0px;
}

.auth-right-wrapper > div {
    margin: 0px;
    padding: 0px 16px 0px 0px;
}

@media screen and (min-width: 772px) {
    .auth-style {
        padding-right: 16px;
    }
}

@media screen and (min-width: 768px) {
    .auth-left-wrapper > div {
        padding: 55px 115px 65px;
        margin: 8px;
    }
    .auth-right-wrapper > div {
        padding: 55px 130px 65px;
        margin: 8px;
    }
}

@media screen and (min-width: 1200px) {
   


    .credit {
        display: block;
        color: #a7abbb;
        position: absolute;
        bottom: 0px;
        margin-bottom: 5px;
        font-size: 11px;
    }
    
    .credit a {
        color: #a7abbb !important;
        text-decoration: underline;
    }
}
