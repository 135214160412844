html {
    height: 100%;
}

#root {
    height: 100%;
}

.title {
    font-size: 36px !important;
    font-weight: 500 !important;
}

body {
    color: #fff;
    height: 100%;
    /*font-family: 'Poppins', sans-serif !important;*/
    font-family: AvenirNextLTPro,Arial,Roboto,-apple-system,system-ui,Segoe UI,Ubuntu,Cantarell,Noto Sans,sans-serif,Oxygen,Open Sans,Helvetica Neue,Icons16 !important;
    overflow: visible !important;
}

.Mui-disabled {
    opacity:0.7;
}

.MuiTypography-root {
    /*font-family: 'Poppins', sans-serif !important;*/
    font-family: AvenirNextLTPro,Arial,Roboto,-apple-system,system-ui,Segoe UI,Ubuntu,Cantarell,Noto Sans,sans-serif,Oxygen,Open Sans,Helvetica Neue,Icons16 !important;

}

body .school-search-container {
    margin: 10px;
}

body .school-search {
    border-color: #858D9E !important;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    border-radius: 3px;
    color: #A7ABBB !important;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 10px;
    width: calc(100% - 22px);
    padding: 10px;
}

body .MuiPopover-paper {
    background-color: #0D1D37;
}

body .MuiOutlinedInput-notchedOutline {
    border-color: #858D9E !important;
}

body .MuiOutlinedInput-input,
body .MuiInputBase-input-MuiOutlinedInput-input,
body .MuiInputBase-input.MuiOutlinedInput-input::placeholder,
body .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder,
body .MuiInputBase-input.MuiOutlinedInput-input::-moz-placeholder,
body .MuiInputBase-input.MuiOutlinedInput-input:-ms-input-placeholder {
    color: #A7ABBB !important;
    font-family: 'Poppins', sans-serif !important;
}

body .MuiFormHelperText-root {
    padding-top: 10px;
    font-size: 14px;
    color: #A7ABBB !important;
    font-family: 'Poppins', sans-serif !important;
}


body .MuiOutlinedInput-input.MuiInputBase-input  {
    color: #A7ABBB !important;
    font-family: 'Poppins', sans-serif !important;
}

body svg.MuiSelect-icon {
    color: #A7ABBB !important;
    font-family: 'Poppins', sans-serif !important;
}

body .MuiInputLabel-outlined.MuiFormLabel-root {
    color: #A7ABBB !important;
    font-family: 'Poppins', sans-serif !important;
}

body h4 {
    color: #fff !important;
    font-size: 24px !important;
}

body .form-title {
    margin: 20px 0px;
}

body .MuiButton-root {
    text-transform: none !important;
}

body .MuiButton-contained.MuiButton-containedPrimary {
    background: #2F80ED !important;
    color: #000 !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    padding: 12px 80px 12px;
    font-family: 'Poppins', sans-serif !important;
}

.white-label {
    color: #fff !important;
}

.terms-checkbox {
    padding: 0px 10px !important;
    align-self: start;
}

.terms-checkbox svg {
    fill: #2F80ED !important;
}

a {
   color: white !important;
   text-decoration: none;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container .logo-text {
    margin-left: 3px;
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 20px;
    color: black;
}

.logo-image {
    height: 300px;
    width: 300px;
}
.alert {
    position: absolute;
    z-index: 2;
    bottom: 50px;
    width: 40%;
    left: 5%;
}
.subheading {
    font-size: 24px;
    letter-spacing: 0.02em;
    font-weight: normal;
    color: #D0D2D9 !important;
}

.pill-box {
    background: linear-gradient(12deg, #1F2539 2.43%, #131A2A 19.9%, #131A2A 82.31%, #1F2539 98.29%);
    border-radius: 24px;   
    position: relative;
}

.pill-box-white {
    background: white;
    color: black;
}


.testimonial-title {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    text-align: left;
    padding-bottom: 25px;
}

.testimonial-body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    text-align: left;
    padding-bottom: 27px;
}

.testimonial-footer {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #858D9E;
}

body .green {
    color: #39d78b !important;
}

body .blue {
    color: #4B98FF !important;
}

body .gray {
    color: #A7ABBB !important;
}

body .white {
    color: white;
}

body .black {
    color: #222;
}

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

ul.MuiMenu-list {
    padding: 0px;
}
  

.spinner:before,
.spinner:after {  
    position: absolute;
    content: '';
}

.reactWindowOption {
    color: #FFF !important;
}

#demo-video {
    height: 315px !important;
}


.contact-container {
    margin: 0px 15px;
    display: flex;
}

.spinner:before {

    width: 10.4px;
  
    height: 20.4px;
  
    background: #2F80ED;
  
    border-radius: 20.4px 0 0 20.4px;
  
    top: -0.2px;
  
    left: -0.2px;
  
    -webkit-transform-origin: 10.4px 10.2px;
  
    transform-origin: 10.4px 10.2px;
  
    -webkit-animation: loading 2s infinite ease 1.5s;
  
    animation: loading 2s infinite ease 1.5s;
  
  }
  
  
  .spinner:after {
  
    width: 10.4px;
  
    height: 10.2px;
  
    background: #2F80ED;
  
    border-radius: 0 10.2px 10.2px 0;
  
    top: -0.1px;
  
    left: 10.2px;
  
    -webkit-transform-origin: 0px 10.2px;
  
    transform-origin: 0px 10.2px;
  
    -webkit-animation: loading 2s infinite ease;
  
    animation: loading 2s infinite ease;
  
  }

  @keyframes loading {

    0% {
  
      -webkit-transform: rotate(0deg);
  
      transform: rotate(0deg);
  
    }
  
    100% {
  
      -webkit-transform: rotate(360deg);
  
      transform: rotate(360deg);
  
    }
  
  }

/* Avenir Next LT Pro */

/* Demi */
  @font-face {
    font-weight: 600;
    font-family: 'AvenirNextLTPro';
    src: url('assets/webfonts/3694B0_0_0.eot');
    src: url('assets/webfonts/3694B0_0_0.eot?#iefix') format('embedded-opentype'),
      url('assets/webfonts/3694B0_0_0.woff2') format('woff2'),
      url('assets/webfonts/3694B0_0_0.woff') format('woff'),
      url('assets/webfonts/3694B0_0_0.ttf') format('truetype');
  }

  /* Medium */
@font-face {
    font-weight: 500;
    font-family: 'AvenirNextLTPro';
    src: url('assets/webfonts/3694B0_2_0.eot');
    src: url('assets/webfonts/3694B0_2_0.eot?#iefix') format('embedded-opentype'),
      url('assets/webfonts/3694B0_2_0.woff2') format('woff2'),
      url('assets/webfonts/3694B0_2_0.woff') format('woff'),
      url('assets/webfonts/3694B0_2_0.ttf') format('truetype');
  }
  
  /* Regular */
  @font-face {
    font-weight: 400;
    font-family: 'AvenirNextLTPro';
    src: url('assets/webfonts/3694B0_1_0.eot');
    src: url('assets/webfonts/3694B0_1_0.eot?#iefix') format('embedded-opentype'),
      url('assets/webfonts/3694B0_1_0.woff2') format('woff2'),
      url('assets/webfonts/3694B0_1_0.woff') format('woff'),
      url('assets/webfonts/3694B0_1_0.ttf') format('truetype');
  }

  /* Monotype Sabon */

/* Semibold */
@font-face {
    font-weight: 600;
    font-family: 'SabonMTStd';
    src: url('assets/webfonts/3694B0_3_0.eot');
    src: url('assets/webfonts/3694B0_3_0.eot?#iefix') format('embedded-opentype'),
      url('assets/webfonts/3694B0_3_0.woff2') format('woff2'),
      url('assets/webfonts/3694B0_3_0.woff') format('woff'),
      url('assets/webfonts/3694B0_3_0.ttf') format('truetype');
  }

  
/* Roboto Mono */
@font-face {
    font-weight: 400;
    font-family: 'Roboto Mono';
    src: url('assets/webfonts/RobotoMono-Regular.ttf');
  }